import React from 'react';
import qr from 'qr-image';
import Utils from '../utility/Utils';
import PropTypes from 'prop-types';

const qrImageSticker = {
    width: '3.74in',
    height: '1.60in',
    display: 'flex',
    display: 'inline-flex'
}

const qrImageContainer = {
    width: '1.55in',
    height: '1.59in',
    float: 'left',
    display: 'grid',
    //background-color: #008E0
}

const qrDetailContainer = {
    width: '2.19in',
    height: '1.59in',
    textAlign: 'left',
    float: 'right',
    display: 'grid'
    //background-color: #00008E
}

const equipmentNo = {
    fontSize: '18px'
}

const qrDetailContent = {
    maxWidth: '2.19in',
    // color: '#585858',
    fontWeight: 'bolder',
    fontFamily: 'Arial, Helvetica, sans-serif',
    textShadow: 'none',
    fontSize: '12px',
    wordBreak: 'break-all',
    marginTop: '20px',
    marginLeft: '-10px'
}

const qrImage = {
    margin: 'auto',
    height: '135px',
    width: '135px'
}

const technipfmcLogoQr = {
    height: '5mm',
    width: '128px',
    marginBottom: '1px'
}

const qrDetailText = {
    margin: 'unset',
    lineHeight: 1.42,
}

const AWOQRSticker = (defaultProps) => {

    const props = {
        qrValue: {
            awoId: Utils.emptyGuid,
            awoNumber: '',
            customerName: '',
            padName: '',
            wellName: '',
            functionalLocation: '',
            system: '',
            wellColorSystem: '',
        },
        ...defaultProps
    }

    const generateQRCodes = (awoId) => {
        const qrSerialValues = awoId;
        const server = {
            ssl: process.env.REACT_APP_API_SSL,
            domain: process.env.REACT_APP_API_HOST_NAME,
            port: process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : '',
            vdir: process.env.REACT_APP_API_VDIR ? '/' + process.env.REACT_APP_API_VDIR : '',
            cors: process.env.REACT_APP_API_CORS === "true" ? true : false,
        }
        if (server.domain === 'localhost') {
            server.port = '3001';
        }
        const url = '{ssl}://{domain}:{port}{vdir}/'.interpolate(server);
        const generatedUrl = url + '#/scannedqrasset';
        const pngArray = qr.imageSync(generatedUrl + '/' + qrSerialValues, { type: 'png' });
        const generatedQRCode = btoa(String.fromCharCode.apply(null, pngArray));
        return generatedQRCode;
    }

    const validateValue = (value) => {
        value = (value == null || value == '' || value == 'N/A' || value.toLowerCase() == 'null')
            ? 'N/A'
            : value;
        return value;
    }

    const formLine = (firstValue, secondValue, isSizeAndLength) => {
        let line;
        if (firstValue == 'N/A' && secondValue == 'N/A') {
            return '';
        }
        else if (firstValue != 'N/A' && secondValue == 'N/A') {
            line = firstValue;
        }
        else if (firstValue == 'N/A' && secondValue != 'N/A') {
            line = secondValue;
        }
        else {
            line = firstValue + ', ' + secondValue
        }
        return line;
    }

    const awoId = props.qrValue.id;
    const awoNumber = props.qrValue.awoNumber;
    const customer = props.qrValue.customerName;
    const padName = props.qrValue.padName;
    const wellName = props.qrValue.wellName;
    const functionalLocation = props.qrValue.functionalLocation;
    const padWel = formLine(padName, wellName, false);
    // const cwpRow = formLine(id, cwp, false);
    const system = props.qrValue.wellColorSystem;
    const qrCode = generateQRCodes(awoId);
    //const partNumberRow = (!partNumber.includes('N/A') && partNumber != null && partNumber.toLowerCase() != 'null' && partNumber != '' && partNumber != '')
    //    ? <span>PN: {partNumber}<br /></span>
    //    : null;
    //const equipmentNoRow = equipmentNumber
    //    ? <span style={equipmentNo}>EQ: {equipmentNumber}<br /></span>
    //    : null;
    return (
        <div style={qrImageSticker}>
            <div style={qrImageContainer} className='qr-image-container'>
                <img style={qrImage} className='qr-image' src={'data:image/png;base64,' + qrCode} />
            </div>
            <div style={qrDetailContainer} className='qr-detail-container'>
                <div style={qrDetailContent} className='qr-detail-content'>
                    <img style={technipfmcLogoQr} className='technipfmc-logo-qr' src='./assets/images/logo-black.png' />
                    <span className='qr-detail-text'>
                        <p style={qrDetailText}>
                            AWO: {awoNumber}
                            <br />
                            {customer}
                            <br />
                            {padWel}
                            <br />
                            {functionalLocation}
                            <br />
                            {system}
                        </p>
                    </span>
                </div>
            </div>
        </div>
    );
}

AWOQRSticker.propTypes = {
    qrValue: PropTypes.object
}

export default AWOQRSticker;