import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import DropZone from '../../components/DropZone';
import Controller from '../../mixins/Controller';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import FileNameRow from '../pages/FileNameRow';
import InspectionAttachmentController from '../controllers/InspectionAttachmentController';
import Table from '../../components/Table';
import Form from '../../components/Form';

const DocumnetUpload = (props) => {

    const handleFileUpload = (res) => {
        props.controller.uploadFile(res);
    }

    const binder = props.binder;
    const controller = props.controller;
    const files = controller.state.files || [];
    const msg = files.length === 0 ? <Table.Row>
        <Table.Data></Table.Data>
        <Table.Data colSpan={7}>No Files Found</Table.Data>
    </Table.Row> : null;
    const rows = files.map(fc => {
        if (fc.state.file.recordStatus !== 'D') {
            return <FileNameRow key={fc.key} controller={fc} />;
        }
    });
    const upload = <DropZone className='float-right' onDrop={handleFileUpload} multiple={false} disabled={controller.adding | controller.state.isSaveInProgress} style={{ borderStyle: 'none', padding: 'unset' }} width={200} height={50} >
        <Form.FieldHint hint='Drag &amp; Drop &rarr;' />&nbsp;
            <Button type='file' className='' variant='success' size='sm' icon='fa-upload' disabled={controller.adding | controller.state.isSaveInProgress} >Upload</Button>
        </DropZone>;

    return (
            <div>
                <div className='row mb-2'>
                    <div className='col-6'><Form.Label name='Documents (Max. file size allowed is 25 MB)' /> </div>
                    <div className='col-6'>{upload}</div>
                </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Table id='documents'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Head className='bg-primary table-dark col-action' />
                                        <Table.Head className='bg-primary table-dark col-fileName'>Filename</Table.Head>
                                        <Table.Head className='bg-primary table-dark col-attachment'>Attachments</Table.Head>
                                        <Table.Head className='bg-primary table-dark col-testName'>JobNumber</Table.Head>
                                        <Table.Head className='bg-primary table-dark col-testName'>ServiceOrder</Table.Head>
                                        <Table.Head className='bg-primary table-dark col-testName'>TestName</Table.Head>
                                        <Table.Head className='bg-primary table-dark col-testName'>Uploaded By</Table.Head>
                                        <Table.Head className='bg-primary table-dark col-dateTime'>Uploaded On</Table.Head>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {rows}
                                    {msg}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
    );
}


const Buttons = (props) => {

    let history = useHistory();

    const handleSave = () => {
        props.controller.save(() => {
            history.goBack();
        });
    }

    const handleClose = () => {
        history.goBack();
    }

    const controller = props.controller;
    return (
        <div className='col-md-12'>
            <div className='edit-buttons pull-right mr-2'>
                <Button id='btn-save' variant='primary' size='sm' className='action-button' disabled={controller.state.isSaveInProgress} onClick={handleSave}>Save</Button>
                <Button id='btn-cancel' variant='secodary' size='sm' className='action-button' onClick={handleClose}>Close</Button>
            </div>
        </div>
    );
}

const InspectionAttachmentPageHeader = (props) => <PageHeader title={props.pageTitle} showBackNavigation='true'/>

class InspectionAttachmentPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        const assetId = this.props.match.params.assetId;
        const assetSerialNumber = this.props.match.params.assetSerialNumber;
        const equipmentNumber   = this.props.match.params.equipmentNumber;
        this.props.controller.load(assetId, assetSerialNumber, equipmentNumber);
    }

    static defaultProps = {
        controller: new InspectionAttachmentController()
    }

    componentDidMount = () => {
        this.initializeMixin();
    }

    render = () => {
        const binder = this.props.binder;
        const controller = this.props.controller;
        const buttons = controller.state.files.length !== 0
            ? <Buttons controller={controller} />
            : null;
        return (
            <Page {...this.props} pageTitle='InteServ · Asset Attachments' id='asset-attachments'>
                <InspectionAttachmentPageHeader pageTitle={'Asset Attachments'} />
                <Page.Content>
                <div className='row'>
                <div className='col-12 mb-1'><span className='h5'>Serial Number: {controller.state.serialNumber}; Equipment Number: {controller.state.equipmentNumber}</span></div>
                    <div className='col-12 job-attachments-content'>
                        <DocumnetUpload controller={controller} binder={binder} />
                    </div>
                    {/* {buttons} */}
                </div>
                </Page.Content>
            </Page>
        );
    }
}

export default InspectionAttachmentPage;