import React from 'react';
import Form from '../../components/Form';
import Binder from '../../lib/Binder';
import Controller from '../../mixins/Controller';
import ActionIcon from '../../components/ActionIcon';
import Dialog from '../../components/Dialog';
import AppConstants from '../utility/AppConstants';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import co from '../../lib/Co';
import Table from '../../components/Table';


class FileNameRow extends React.Component {
    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
    }
    componentDidMount = () => {
        this.initializeMixin();
        this.binder = new Binder(this);
    }

    handleDeleteOrderNumber = () => {
        co(this, function* () {
            const result = yield Dialog.showDialogWaitForResult(
                <Dialog.OKCancelDialog className='delete-confirm-dialog' header='Confirm Remove'>
                    {'Are you sure you want to remove the file'}
                </Dialog.OKCancelDialog>
            );

            if (result.OK) {
                this.props.controller.removeRow();
            }
        });
    }
    

    render = () => {
        const controller = this.props.controller;
        const flag=this.props.flag || '';
        const binder = this.binder;
        const canRemoveRow = controller.canRemoveRow();
        const uploadedOn = DateTimeFormatter.formatDateTime(controller.state.file.uploadedOn);
        const removeButton = canRemoveRow ? <ActionIcon icon='fa-trash' className='remove-handle' action={this.handleDeleteOrderNumber} /> : null;
        const check=(fileName)=>{
            console.log(fileName);
            this.props.controller.getFile();
        }
        const fldFileName = controller.state.file 
            ? <a  onClick={() => check(controller.state.file.fileName)} className='d-flex text-decoration-none'>
            {controller.state.file.fileName}
            </a>
            : null;
        const jobNumber = controller.state.file.jobNumber && flag!='J'
            ? <Table.Data className='col-jobnumber'>{controller.state.file.jobNumber}</Table.Data>
            : uploadedOn && flag!='J' ? <Table.Data className='col-jobnumber'></Table.Data> : null
        const soId = controller.state.file.soId && flag!='J'
            ? <Table.Data className='col-soid'>{controller.state.file.soId}</Table.Data>
            : uploadedOn && flag!='J'? <Table.Data className='col-soid'></Table.Data> : null
        const testName = controller.state.file.testName && flag!='J'
            ? <Table.Data className='col-testName'>{controller.state.file.testName}</Table.Data>
            : uploadedOn && flag!='J'? <Table.Data className='col-testName'></Table.Data> : null
        const fileView = //<Table.Data className='col-testName'></Table.Data>
        uploadedOn && flag!='J'
            ? AppConstants.imgFileTypes.includes(controller.state.file.fileName.split('.')[1].toLowerCase()) && flag!='J'
                ? <Table.Data id='divattimgs'>
                    {/* <a><img className='inspection-image-thumbnail' src={controller.state.file.imgUrl} alt='image' /> <div className='hover-img'><span><img src={controller.state.file.imgUrl} alt='image' height='100' /></span></div></a> */}
                </Table.Data>
                : <Table.Data className='col-testName'></Table.Data> : null;
        const uploadedBy = controller.state.file.uploadedBy
            ? <Table.Data className='col-testName'>{controller.state.file.uploadedBy}</Table.Data>
            : uploadedOn ? <Table.Data className='col-testName'></Table.Data> : null
        const uploadeDateTime = uploadedOn
            ? <Table.Data className='col-uploadedon'>{uploadedOn}</Table.Data>
            : null;

        return (
            <Table.Row>
                <Table.Data className='col-action'>{removeButton}</Table.Data>
                <Table.Data className='col-fileName'>{fldFileName}</Table.Data>
                {fileView}
                {jobNumber}
                {soId}
                {testName}
                {uploadedBy}
                {uploadeDateTime}
            </Table.Row>
        )
    }
}

export default FileNameRow;

