import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import Validator from '../../lib/Validator';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import LoginService from '../services/LoginService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import moment from 'moment';
import $ from 'jquery';
import { set } from 'immutable';
import SecurityRights from '../utility/SecurityRights';

class ReportsController extends BaseController {
    constructor() {
        super('reports', ReportsController.getInitialState());
        this._validator = new Validator();
        this._validator.add('reportFormat', 'Report Format', [{ required: true }]);
    }

    static getInitialState = () => {
        return {
            serialNo: '',
            equipmentNumber: '',
            reportFormat: AppConstants.ReportFormat[0],
            reportName: '',
            reportUrl: '',
            selectedFacility: '',
            selectedCustomer: '',
            selectedServicePerformed: '',
            selectedCurrentLocation: '',
            useCustomerLocation: false,
            selectedCustomerLocation: '',
            selectedTestName: '',
            selectedTechnician: '',
            calPercent: 10,
            fromDate: '',
            toDate: '',
            dueDate: '',
            needService: false,
            selectedRegion: '',
            selectedJobNumber: '',
            selectedShippingTicket: '',
            selectedRecievedTicket: '',
            selectedContainer: '',
            selectedFamilyCode: '',
            selectedStyle: '',
            selectedNominalSize: '',
            selectedStatus: '',
            selectedManufacturer: '',
            selectedLength: '',
            selectedMfgItemNo: '',
            groupNumber: '',
            selectedItems: [],
            formDisplay: false,
            formFields: {},
            errors: {},
            reportList: [],
            reportsGroupedByCategoryName: {},
            defaultReport: 'Asset Reports',
            isSignatureRequired: true,
            isGeneratingReport: false,
            selectedServiceOrder: '',
            includeFailedInspection: false,
            generateAsZip: false,
            searchActive: true,
            searchCompleted: false,
            dropOffNumber: '',
            activeNode: '',
            selectedMultiCustomer: '',
            selectedMultiCustomerLocation: '',
            selectedMultiRegion: '',
            selectedMultiFamilyCode: '',
            selectedMultiStyle: '',
            selectedMultiMfgItem: '',
            includeServiceLevels: false,
            selectedMultiSLevels: '',
            generateofflinezip: true,
            includegauges: true,
            isSignedCertificate: false,
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.selectedFacility === undefined && nextState.selectedFacility === null) {
            this.state.selectedFacility = '';
            this.commit();
        }
    }
    searchActiveChanged = () => {
        this.state.searchCompleted = false;
    }

    searchCompletedChanged = () => {
        this.state.searchActive = false;
    }
    loadValidator = () => {
        const s = this.state;
        const { customerName, serial, facility, servicePerformed, currentLocation, customerLocation, testName, technician, calPercent, fromDate, toDate, dueDate, jobNumber, shippingTicket, recievedTicket, container, funcLocation, groupNumber, region, needService, useCustomerLocation, familyCode, nominalSize, manufacturer, fontFamily, multicustomerName, multicustomerLocation, multiregion, equipmentNumber, generateofflinezip } = s.formFields;
        this._validator.removeAll();
        if (facility && facility.isRequired) {
            this._validator.add('selectedFacility', 'Facility', [{ required: true }]);
        }
        if (customerName && customerName.isRequired) {
            this._validator.add('selectedCustomer', 'Customer Name', [{ required: true }]);
        }
        if (servicePerformed && servicePerformed.isRequired) {
            this._validator.add('selectedServicePerformed', 'Service Performed', [{ required: true }]);
        }
        if (serial && serial.isRequired) {
            this._validator.add('serialNo', 'Serial', [{ required: true }]);
        }
        if (currentLocation && currentLocation.isRequired) {
            this._validator.add('selectedCurrentLocation', 'Current Location', [{ required: true }]);
        }
        if (customerLocation && customerLocation.isRequired) {
            this._validator.add('selectedCustomerLocation', 'Customer Location', [{ required: true }]);
        }
        if (testName && testName.isRequired) {
            this._validator.add('testName', 'Test Name', [{ required: true }]);
        }
        if (technician && technician.isRequired) {
            this._validator.add('technician', 'Technician', [{ required: true }]);
        }
        if (calPercent && calPercent.isRequired) {
            this._validator.add('calPercent', 'Cal Percent', [{ required: true }]);
        }
        if (equipmentNumber && equipmentNumber.isRequired) {
            this._validator.add('equipmentNumber', 'Equipment Number', [{ required: true }]);
        }
        if (fromDate && fromDate.isRequired) {
            this._validator.add('fromDate', 'From Date', [{ required: true }]);
        }
        if (toDate && toDate.isRequired) {
            this._validator.add('toDate', 'Thru Date', [{ required: true }]);
        }
        if (dueDate && dueDate.isRequired) {
            this._validator.add('dueDate', 'Due Date', [{ required: true }]);
        }
        if (needService && needService.isRequired) {
            this._validator.add('needService', 'Need Service', [{ required: true }]);
        }
        if (useCustomerLocation && useCustomerLocation.isRequired) {
            this._validator.add('useCustomerLocation', 'Use Customer Location', [{ required: true }]);
        }
        if (region && region.isRequired) {
            this._validator.add('selectedRegion', 'Region', [{ required: true }]);
        }
        if (jobNumber && jobNumber.isRequired) {
            this._validator.add('selectedJobNumber', 'Job Number', [{ required: true }]);
        }
        if (shippingTicket && shippingTicket.isRequired) {
            this._validator.add('selectedShippingTicket', 'Shipping Ticket', [{ required: true }]);
        }
        if (recievedTicket && recievedTicket.isRequired) {
            this._validator.add('selectedRecievedTicket', 'Ticket', [{ required: true }]);
        }
        if (container && container.isRequired) {
            this._validator.add('selectedContainer', 'Container', [{ required: true }]);
        }
        if (groupNumber && groupNumber.isRequired) {
            this._validator.add('groupNumber', 'Group Number', [{ required: true }]);
        }
        if (familyCode && familyCode.isRequired) {
            this._validator.add('selectedFamilyCode', 'Family Code', [{ required: true }]);
        }
        if (manufacturer && manufacturer.isRequired) {
            this._validator.add('selectedManufacturer', 'Manufacturer', [{ required: true }]);
        }
        if (nominalSize && nominalSize.isRequired) {
            this._validator.add('selectedNominalSize', 'Nominal Size', [{ required: true }]);
        }
        if (multicustomerName && multicustomerName.isRequired) {
            this._validator.add('selectedMultiCustomer', 'Customer Name', [{ required: true }]);
        }
        if (multicustomerLocation && multicustomerLocation.isRequired) {
            this._validator.add('selectedMultiCustomerLocation', 'Customer Location', [{ required: true }]);
        }
        if (multiregion && multiregion.isRequired) {
            this._validator.add('selectedMultiRegion', 'Region', [{ required: true }]);
        }
        if (fontFamily && fontFamily.isRequired) {
            this._validator.add('selectedFamilyCode', 'Family Code', [{ required: true }]);
            this._validator.add('selectedStyle', 'Style', [{ required: true }]);
            this._validator.add('selectedNominalSize', 'Nominal Size', [{ required: true }]);
            this._validator.add('selectedManufacturer', 'Manufacturer', [{ required: true }]);
            this._validator.add('selectedLength', 'Length', [{ required: true }]);
            this._validator.add('selectedMfgItemNo', 'Mfg Item No', [{ required: true }]);

            if (fontFamily.status) {
                this._validator.add('selectedStatus', 'Status', [{ required: true }]);
            }
        }
        this.commit();
    }

    clear = () => {
        this.clearFormValue();
        this.commit();
    }

    validate = () => {
        this.state.errors = {};
        this.commit();
        if (this.state.fromDate) {
            const fromDateValue = moment(this.state.fromDate);
            this._validator.add('toDate', 'Thru date must be greater than from date.', [{ isGreaterThanEqualsDate: { params: fromDateValue } }]);
        } else {
            const s = this.state;
            const { fromDate } = s.formFields;
            fromDate && fromDate.isRequired ? this._validator.add('fromDate', 'From Date', [{ required: true }]) : this._validator.remove('fromDate');
        }
        if (this.state.toDate) {
            const toDateValue = moment(this.state.toDate);
            this._validator.add('fromDate', 'From date must be lesser than to date.', [{ isLessThanEqualsDate: { params: toDateValue } }]);
        } else {
            const s = this.state;
            const { toDate } = s.formFields;
            toDate && toDate.isRequired ? this._validator.add('toDate', 'Thru Date', [{ required: true }]) : this._validator.remove('toDate');
        }
        this._validator.validateAll(this.state);
        let errors = Object.keys(this.state.errors);
        if (!errors.length) {
            return true;
        }
        this.commit();
        return false;
    }

    clearFormValue = () => {
        this.state.serialNo = '';
        this.state.reportFormat = AppConstants.ReportFormat[0];
        this.state.selectedFacility = '';
        this.state.selectedCustomer = '';
        this.state.selectedServicePerformed = '';
        this.state.selectedTestName = '';
        this.state.selectedTechnician = '';
        this.state.useCustomerLocation = false;
        this.state.calPercent = 10;
        this.state.equipmentNumber = '';
        this.state.fromDate = '';
        this.state.toDate = '';
        this.state.dueDate = '';
        this.state.needService = false;
        this.state.selectedJobNumber = '';
        this.state.selectedShippingTicket = '';
        this.state.selectedRecievedTicket = '';
        this.state.selectedContainer = '';
        this.state.selectedStyle = '';
        this.state.selectedStatus = '';
        this.state.selectedLength = '';
        this.state.selectedMfgItemNo = '';
        this.state.groupNumber = '';
        this.state.errors = {};
        this.state.selectedCurrentLocation = '';
        this.state.selectedCustomerLocation = '';
        this.state.selectedManufacturer = '';
        this.state.selectedNominalSize = '';
        this.state.selectedRegion = '';
        this.state.selectedFamilyCode = '';
        this.state.isSignatureRequired = true;
        this.state.includeFailedInspection = false;
        this.state.generateAsZip = false;
        this.state.selectedServiceOrder = '';
        this.state.searchActive = true;
        this.state.searchCompleted = false;
        this.state.dropOffNumber = '';
        this.state.selectedMultiCustomer = [];
        this.state.selectedMultiCustomerLocation = [];
        this.state.selectedMultiRegion = [];
        this.state.selectedMultiFamilyCode = [];
        this.state.selectedMultiStyle = [];
        this.state.selectedMultiMfgItem = [];
        this.state.selectedMultiSLevels = [];
        this.state.includeServiceLevels = false;
        this.state.generateofflinezip = true;
        this.state.includegauges = true;
        this.state.isSignedCertificate = false;
        $('#multiCustomerLocation').select2('data', []);
        $('#multiManufacturer').select2('data', []);
        $('#multifamilyCode').select2('data', []);
        $('#multinominalSize').select2('data', []);
        $('#multifacility').select2('data', []);
        $('#multiMfgItemNo').select2('data', []);
        $('#multiStyle').select2('data', []);
        this.commit()
    }

    clearFormFields = () => {
        this.state.formFields = {
            customerName: '',
            serial: '',
            facility: '',
            servicePerformed: '',
            currentLocation: '',
            customerLocation: '',
            functionalLocation: '',
            equipmentNumber: '',
            testName: '',
            technician: '',
            calPercent: '',
            fromDate: '',
            toDate: '',
            dueDate: '',
            jobNumber: '',
            shippingTicket: '',
            recievedTicket: '',
            container: '',
            groupNumber: '',
            region: '',
            needService: '',
            useCustomerLocation: '',
            fontFamily: '',
            serviceOrder: '',
            multicustomerName: '',
            multicustomerLocation: '',
            multiregion: '',
            multifamilycode: '',
            multistyle: '',
            multimfgitem: '',
            includegauges: true
        };
        this.commit();
    }

    getReportDetails(formFields, reportName, reportUrl, categoryName) {
        this.state.categoryName = categoryName;
        this.state.reportUrl = reportUrl;
        this.state.formDisplay = reportName ? true : false;
        this.state.formFields = formFields;
        this.state.reportName = reportName;
        this.commit();
    }

    getReportList = () => {
        co(this, function* () {
            const reports = yield ajax.get('lookup/getReportList', { isCustomer: LoginService.isCustomer });
            if (reports) {
                this.state.reportList = reports;
                const groupedByCategoryName = this.groupBy(reports, 'Category_Name');
                this.state.reportsGroupedByCategoryName = groupedByCategoryName;
                this.commit();
            } else {
                this.state.reportList = [];
                this.commit();
            }
        });
    }

    groupBy = (array, prop) => {
        let treeNodes = [];
        let reportCategories = array.map(item => item.Category_Name)
        reportCategories = [...new Set(reportCategories)];
        reportCategories.forEach(item => {
            const reportNodes = array.filter(node => node.Category_Name === item).map(report => {
                const childNode = {
                    icon: 'far fa-chart-bar',
                    key: report.Report_Name.split(' ').join(''),
                    label: report.Report_Name,
                    reportId: report.Report_GUID,
                    reportUrl: report.ReportUrl
                }
                return childNode;
            });
            const nodeObject = {
                key: item.split(' ').join(''),
                label: item,
                nodes: reportNodes
            }
            treeNodes.push(nodeObject);
        });
        return treeNodes;
    }

    getReport = () => {
        if (!this.validate()) {
            return false;
        }
        const { customerName, serial, isSignatureRequired, facility, servicePerformed, currentLocation, customerLocation, testName, technician, calPercent, fromDate, toDate, dueDate, jobNumber, shippingTicket, recievedTicket, container, groupNumber, region, needService, useCustomerLocation, familyCode, manufacturer, fontFamily, serviceOrder, includeFailedInspection, generateAsZip, multicustomerName, multiregion, multicustomerLocation, multifamilycode, multistyle, multimfgitem, multislevels, includeServiceLevels, functionalLocation, equipmentNumber, generateofflinezip, includegauges } = this.state.formFields;
        const { reportName, reportUrl, reportFormat } = this.state;
        const s = this.state;

        if (reportName === 'Asset Certificate' || reportName === 'Asset Certificate - Non-Standard') {

            if (!this.state.generateofflinezip) {
                if (!s.serialNo && !s.selectedCustomerLocation && !s.fromDate && !s.toDate && !s.selectedJobNumber && !s.selectedShippingTicket && !s.selectedContainer && !s.selectedFamilyCode && !s.selectedStyle && !s.selectedNominalSize && !s.selectedStatus && !s.selectedManufacturer && !s.selectedLength && !s.selectedMfgItemNo && !s.selectedServiceOrder) {
                    notification.action('error').post('Customer and another field(apart from Signature Required?) should be inputted to generate report.');
                    return false;
                }
            }
            else {
                if (!s.selectedJobNumber && !s.fromDate && !s.toDate && !s.selectedShippingTicket & !s.selectedContainer & !s.selectedServiceOrder & !s.serialNo) {
                    notification.action('error').post('Please select Job Number or Shipping Ticket Number or Containers or Svc Date And Thru (with other filters)');
                    return false;
                }
                if (s.fromDate && s.toDate) {
                    var date1 = new Date(s.fromDate);
                    var date2 = new Date(s.toDate);
                    var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
                    if (diffDays > 180) {
                        notification.action('error').post('Pleaase select Svc Date And Thru within interval of six months');
                        return false;
                    }

                }
            }
        }

        this.state.isGeneratingReport = true;
        this.commit();

        const payload = {
            reportName: reportName,
            reportUrl: reportUrl,
            reportFormat: reportFormat && reportFormat.name ? (reportFormat.name).toUpperCase() : 'PDF',
        };

        const customerLocationValue = s.selectedCustomerLocation ? s.selectedCustomerLocation.length ? s.selectedCustomerLocation : s.selectedCustomerLocation : {};
        const manufacturerValue = s.selectedManufacturer ? s.selectedManufacturer.length ? s.selectedManufacturer : s.selectedManufacturer : {};
        const multicustomerValue = s.selectedMultiCustomer ? s.selectedMultiCustomer.length ? s.selectedMultiCustomer : s.selectedMultiCustomer : {};
        const multiregionValue = s.selectedMultiRegion ? s.selectedMultiRegion.length ? s.selectedMultiRegion : s.selectedMultiRegion : {};
        const multicustomerLocationValue = s.selectedMultiCustomerLocation ? s.selectedMultiCustomerLocation.length ? s.selectedMultiCustomerLocation : s.selectedMultiCustomerLocation : {};
        const multifamilycodeValue = s.selectedMultiFamilyCode ? s.selectedMultiFamilyCode.length ? s.selectedMultiFamilyCode : s.selectedMultiFamilyCode : {};
        const multistyleValue = s.selectedMultiStyle ? s.selectedMultiStyle.length ? s.selectedMultiStyle : s.selectedMultiStyle : {};
        const multimfgValue = s.selectedMultiMfgItem ? s.selectedMultiMfgItem.length ? s.selectedMultiMfgItem : s.selectedMultiMfgItem : {};
        const multislevelValue = s.selectedMultiSLevels ? s.selectedMultiSLevels.length ? s.selectedMultiSLevels : s.selectedMultiSLevels : {};


        payload.customerId = customerName ? (s.selectedCustomer ? s.selectedCustomer.id : '') : '';
        payload.customerName = customerName ? s.selectedCustomer ? s.selectedCustomer.name : '' : '';
        payload.serials = serial ? s.serialNo : '';
        payload.isSignatureRequired = isSignatureRequired ? s.isSignatureRequired : '';
        payload.facility = facility ? s.selectedFacility ? s.selectedFacility.id : '' : '';
        payload.facilityName = facility ? s.selectedFacility ? s.selectedFacility.name : '' : '';
        payload.servicePerformed = servicePerformed ? s.selectedServicePerformed ? s.selectedServicePerformed.id : '' : '';
        payload.currentLocation = currentLocation ? s.selectedCurrentLocation ? s.selectedCurrentLocation.id : '' : '';
        payload.customerLocation = customerLocation ? customerLocationValue : '';
        payload.testName = testName ? s.selectedTestName ? s.selectedTestName.id : '' : '';
        payload.technician = technician ? s.selectedTechnician ? s.selectedTechnician.id : '' : '';
        payload.calPercent = calPercent ? s.calPercent : '';
        payload.fromDate = fromDate ? s.fromDate : '';
        payload.toDate = toDate ? s.toDate : '';
        payload.dueDate = dueDate ? s.dueDate : '';
        payload.jobNumber = jobNumber ? s.selectedJobNumber ? s.selectedJobNumber.id : '' : '';
        payload.jobNo = jobNumber ? s.selectedJobNumber ? s.selectedJobNumber.name : '' : '';
        payload.shippingTicket = shippingTicket ? s.selectedShippingTicket ? s.selectedShippingTicket.id : '' : '';
        payload.recievedTicket = recievedTicket ? s.selectedRecievedTicket ? s.selectedRecievedTicket.id : '' : '';
        payload.container = container ? s.selectedContainer ? s.selectedContainer.id : '' : '';
        payload.functionalLocation = functionalLocation ? s.selectedFuncLocation ? s.selectedFuncLocation.desc : '' : '';
        payload.equipmentNumber = equipmentNumber ? s.equipmentNumber : '';
        payload.groupNumber = groupNumber ? s.groupNumber : '';
        payload.region = region ? s.selectedRegion ? s.selectedRegion.id : '' : '';
        payload.needService = needService ? s.needService : '';
        payload.useCustomerLocation = useCustomerLocation ? s.useCustomerLocation : '';
        payload.familyCode = fontFamily ? s.selectedFamilyCode ? s.selectedFamilyCode.id : '' : '';
        payload.manufacturer = manufacturerValue ? manufacturerValue : '';
        // payload.familyCode = fontFamily ? s.selectedFamilyCode ? s.selectedFamilyCode.id : '' : '';
        payload.style = fontFamily ? s.selectedStyle ? s.selectedStyle.id : '' : '';
        payload.nominalSize = fontFamily ? s.selectedNominalSize ? s.selectedNominalSize.id : '' : '';
        payload.inspectionStatus = fontFamily && fontFamily.status ? s.selectedStatus ? s.selectedStatus.name : '' : '';
        //payload.manufacturer = fontFamily ? manufacturerValue : '';
        payload.length = fontFamily ? s.selectedLength ? s.selectedLength.id : '' : '';
        payload.mfgItemNo = fontFamily || reportName == 'PMITP Report' ? s.selectedMfgItemNo ? s.selectedMfgItemNo.id : '' : '';
        payload.serviceOrder = serviceOrder ? s.selectedServiceOrder : '';
        payload.includeFailedInspection = includeFailedInspection ? s.includeFailedInspection : '';
        payload.generateAsZip = generateAsZip ? s.generateAsZip : '';
        payload.isComplete = s.searchActive ? 0 : 1;
        payload.dropOffNumber = s.dropOffNumber ? s.dropOffNumber : '';
        payload.multicustomerName = multicustomerName ? multicustomerValue : '';
        payload.multiregion = multiregion ? multiregionValue : '';
        payload.multicustomerLocation = multicustomerLocation ? multicustomerLocationValue : '';
        payload.multifamilycode = multifamilycode ? multifamilycodeValue : '';
        payload.multistyle = multistyle ? multistyleValue : '';
        payload.multimfgitem = multimfgitem ? multimfgValue : '';
        payload.multislevels = multislevels ? multislevelValue : '';
        payload.includeServiceLevels = includeServiceLevels ? s.includeServiceLevels : '';
        payload.isofflinereport = generateofflinezip ? s.generateofflinezip : '';
        payload.includegauges = includegauges ? s.includegauges : '';
        if (s.isSignatureRequired === true && this.CanEnableSignedCertificate() === true) {
            payload.isSignedCertificate = true;
        }
        co(this, function* () {
            try {
                let results = yield ajax.post('lookup/getReport', payload);
                this.state.isGeneratingReport = false;
                this.commit();
                const hasDescription = ['Asset Certificate', 'Asset Certificate - Non-Standard'].indexOf(reportName) > -1;
                if (s.generateofflinezip && hasDescription) {

                    if (parseFloat(results) > 0) {
                        notification.action('success').post({ msg: `Request with Request No : ${results} is captured successfully. Reports will be available for download shortly under My Report Requests page`, title: 'Success in saving offline report request' });
                    }
                    else {
                        notification.action('error').post({ msg: 'Unable to capture offline report request', title: 'Error in saving offline report request' });
                    }
                }
                else if (s.generateAsZip) {
                    if (results == '') {
                        notification.action('error').post({ msg: 'No Records found', title: 'Error in Report download' });
                    }
                    else {
                        const tempLink = document.createElement('a');
                        let file = Utils.convertBase64toBlob(results, 'application/octet-stream');
                        let fileURL = window.URL.createObjectURL(file);
                        tempLink.href = fileURL;
                        tempLink.download = (s.selectedJobNumber && s.selectedJobNumber.name) ? (s.selectedJobNumber.name + '.zip') : (s.selectedCustomer.name + '_' + DateTimeFormatter.today().format('YYYYMMDD') + '.zip');
                        tempLink.click();
                        tempLink.remove();
                    }
                }
                else if (this.state.reportFormat.name === 'PDF') {
                    if (navigator.userAgent.match(/iPad/i) != null && navigator.userAgent.match(/Chrome/i) != null) {
                        const blob = Utils.convertBase64toBlob(results, 'application/pdf');
                        const fileName = reportName + '.pdf';
                        const fileURL = URL.createObjectURL(blob);
                        const tempLink = document.createElement('a');
                        tempLink.href = fileURL;
                        tempLink.download = fileName;
                        tempLink.click();
                        tempLink.remove();
                    } else {
                        let file = Utils.convertBase64toBlob(results, 'application/pdf');
                        let fileURL = window.URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                } else {
                    const tempLink = document.createElement('a');
                    if (navigator.userAgent.match(/iPad/i) != null && navigator.userAgent.match(/Chrome/i) != null) {
                        const blob = Utils.convertBase64toBlob(results, 'application/vnd.ms-excel');
                        const fileName = payload.reportName + '.xls';
                        const fileURL = URL.createObjectURL(blob);
                        tempLink.href = fileURL;
                        tempLink.download = fileName;
                    } else {
                        let file = Utils.convertBase64toBlob(results, 'application/vnd.ms-excel');
                        let fileURL = window.URL.createObjectURL(file);
                        tempLink.href = fileURL;
                        tempLink.download = payload.reportName + '.xls';
                        document.body.appendChild(tempLink);
                    }
                    tempLink.click();
                    tempLink.remove();
                }
                // this.clear();
                return true;
            }
            catch (err) {
                this.state.isGeneratingReport = false;
                this.commit();
                notification.action('error').post({ msg: err.message, title: 'Error in Report download' });
            }
        });
    }

    selectedCustomerChanged = () => {
        if (this.state.selectedCustomer) {
            // if(this.state.useCustomerLocation) {
            //   this.state.formFields.customerLocation ? this.state.formFields.customerLocation.readOnly = true : null;
            // } else {
            //   this.state.formFields.customerLocation ? this.state.formFields.customerLocation.readOnly = false : null;
            // }
            this.state.formFields.customerLocation && (this.state.formFields.customerLocation.readOnly = this.state.formFields.customerLocation ? false : null);
            this.state.formFields.currentLocation && (this.state.formFields.currentLocation.readOnly = this.state.formFields.currentLocation ? false : null);
            this.state.formFields.multimfgitem && (this.state.formFields.multimfgitem.readOnly = this.state.formFields.multimfgitem ? false : null);
            this.state.formFields.servicePerformed && (this.state.formFields.servicePerformed.readOnly = this.state.formFields.servicePerformed ? false : null);
            this.state.formFields.jobNumber && (this.state.formFields.jobNumber.readOnly = this.state.formFields.jobNumber ? false : null);
            this.state.formFields.shippingTicket && (this.state.formFields.shippingTicket.readOnly = this.state.formFields.shippingTicket ? false : null);
            this.state.formFields.recievedTicket && (this.state.formFields.recievedTicket.readOnly = this.state.formFields.recievedTicket ? false : null);
            this.state.formFields.container && (this.state.formFields.container.readOnly = this.state.formFields.container ? false : null);
        } else {
            this.state.formFields.customerLocation && (this.state.formFields.customerLocation.readOnly = this.state.formFields.customerLocation ? true : null);
            this.state.formFields.currentLocation && (this.state.formFields.currentLocation.readOnly = this.state.formFields.currentLocation ? true : null);
            this.state.formFields.multimfgitem && (this.state.formFields.multimfgitem.readOnly = this.state.formFields.multimfgitem ? true : null);

            this.state.formFields.servicePerformed && (this.state.formFields.servicePerformed.readOnly = this.state.formFields.servicePerformed ? true : null);
            this.state.formFields.jobNumber && (this.state.formFields.jobNumber.readOnly = this.state.formFields.jobNumber ? true : null);
            this.state.formFields.shippingTicket && (this.state.formFields.shippingTicket.readOnly = this.state.formFields.shippingTicket ? true : null);
            this.state.formFields.recievedTicket && (this.state.formFields.recievedTicket.readOnly = this.state.formFields.recievedTicket ? true : null);
            this.state.formFields.container && (this.state.formFields.container.readOnly = this.state.formFields.container ? true : null);
        }
        this.state.selectedCustomerLocation = '';
        this.state.selectedCurrentLocation = '';
        this.state.selectedMfgItemNo = '';
        this.state.selectedServicePerformed = '';
        this.state.selectedJobNumber = '';
        this.state.selectedShippingTicket = '';
        this.state.selectedRecievedTicket = '';
        this.state.selectedContainer = '';
        this.state.selectedMultiCustomerLocation = '';
        this.state.selectedMultiRegion = '';
        this.state.selectedMultiSLevels = '';
        this.commit();
    }

    selectedMultiCustomerChanged = () => {
        if (this.state.selectedMultiCustomer) {
            this.state.formFields.multicustomerLocation.readOnly = this.state.formFields.multicustomerLocation ? false : null;
            this.state.formFields.multiregion.readOnly = this.state.formFields.multiregion ? false : null;
        } else {
            this.state.formFields.multicustomerLocation.readOnly = this.state.formFields.multicustomerLocation ? true : null;
            this.state.formFields.multiregion.readOnly = this.state.formFields.multiregion ? true : null;
        }
        this.state.selectedMultiCustomerLocation = '';
        this.state.selectedMultiRegion = '';
        this.commit();
    }

    selectedFacilityChanged = () => {
        if (this.state.selectedFacility) {
            this.state.formFields.customerLocation && (this.state.formFields.customerLocation.readOnly = this.state.formFields.customerLocation ? false : null);
            this.state.formFields.technician && (this.state.formFields.technician.readOnly = this.state.formFields.technician ? false : null);
            this.state.formFields.jobNumber && (this.state.formFields.jobNumber.readOnly = this.state.formFields.jobNumber ? false : null);
            this.state.formFields.shippingTicket && (this.state.formFields.shippingTicket.readOnly = this.state.formFields.shippingTicket ? false : null);
            this.state.formFields.recievedTicket && (this.state.formFields.recievedTicket.readOnly = this.state.formFields.recievedTicket ? false : null);
            this.state.formFields.recievedTicket && (this.state.formFields.recievedTicket.readOnly = this.state.formFields.container ? false : null);
        } else {
            this.state.formFields.customerLocation && (this.state.formFields.customerLocation.readOnly = this.state.formFields.customerLocation ? true : null);
            this.state.formFields.technician && (this.state.formFields.technician.readOnly = this.state.formFields.technician ? true : null);
            this.state.formFields.jobNumber && (this.state.formFields.jobNumber.readOnly = this.state.formFields.jobNumber ? true : null);
            this.state.formFields.shippingTicket && (this.state.formFields.shippingTicket.readOnly = this.state.formFields.shippingTicket ? true : null);
            this.state.formFields.recievedTicket && (this.state.formFields.recievedTicket.readOnly = this.state.formFields.recievedTicket ? true : null);
            this.state.formFields.container && (this.state.formFields.container.readOnly = this.state.formFields.container ? true : null);
        }
        this.state.selectedCustomerLocation = '';
        this.state.selectedTechnician = '';
        this.state.selectedJobNumber = '';
        this.state.selectedShippingTicket = '';
        this.state.selectedRecievedTicket = '';
        this.state.selectedContainer = '';
        this.commit();
    }

    selectedCustomerLocationChanged = () => {
        // if(this.state.selectedCustomerLocation) {
        //   this.state.formFields.jobNumber        ? this.state.formFields.jobNumber.readOnly        = false : null;
        // } else {
        //   this.state.formFields.jobNumber        ? this.state.formFields.jobNumber.readOnly        = true : null;
        // }
        this.state.selectedJobNumber = '';
        // this.state.selectedShippingTicket = '';
        this.commit();
    }

    // selectedJobNumberChanged(){
    //   if(!this.state.selectedJobNumber){
    //     this.state.generateAsZip = false;
    //   }
    //   this.commit();
    // }

    selectedServicePerformedChanged = () => {
        if (this.state.selectedServicePerformed) {
            if (this.state.formFields.testName) {
                this.state.formFields.testName.readOnly = false;
            }
        } else {
            if (this.state.formFields.testName) {
                this.state.formFields.testName.readOnly = true;
            }
        }
        this.state.selectedTestName = '';
        this.commit();
    }

    setActiveNode = (nodeKey) => {
        this.state.reportName = nodeKey;
        this.commit();
    }
    generateofflinezipChanged = () => {
        this.state.generateAsZip = false;
        this.commit();
    }
    generateAsZipChanged = () => {
        this.state.generateofflinezip = false;
        this.commit();
    }

    CanEnableSignedCertificate = () => {
        if (!SecurityRights.hasSecurityToken(LoginService.loginInfo, SecurityRights.Rights.AssetCertificateApprover)) {
            return false;
        }
        return true;
    }
}

export default ReportsController;