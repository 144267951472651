import React, { useEffect } from 'react';
import TreeView from '../../components/TreeView';
import { useHistory } from 'react-router-dom';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import LoginService from '../services/LoginService';
import Searchers from '../utility/Searchers';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import Utils from '../utility/Utils';
import Authentication from '../services/authentication';
import AppConstants from '../utility/AppConstants';
import ReportsController from '../controllers/ReportsController';
import CustomerCertificateTable from './CustomerCertificateTable';
import VendorReportsController from '../controllers/VendorReportsController';

const ReportTreeView = (props) => {

    const addValidationClass = (field, fieldId) => {
        const control = document.getElementById(fieldId);
        if (control && control.parentNode && control.parentNode.childNodes && control.parentNode.childNodes.length) {
            const nodes = control.parentNode.childNodes;
            if (nodes[0].localName === 'div' && Utils.contains(nodes[0].id, 's2id_')) {
                addClassOrRemoveClass(nodes[0].id, field, nodes[1].value);
            }
            else {
                addClassOrRemoveClass(nodes[1].id, field, nodes[1].value);
            }
        }
    }

    const addClassOrRemoveClass = (id, field, hasValue) => {
        const control = document.getElementById(id);
        if (control) {
            if (field && field.isRequired && !hasValue) {
                control.classList.add('required');
            } else {
                control.classList.remove('required');
            }
        }
    }

    const validatedClass = () => {
        const { formFields } = props.controller.state;
        Object.keys(formFields).map((field) => {
            addValidationClass(formFields[field], field);
        });
    }

    const onNodeClick = (node) => {
        const reportName = node.label;
        const reportUrl = node.reportUrl;
        const categoryName = node.parent;
        props.controller.clear();
        props.controller.setActiveNode();
        switch (reportName) {
            case 'Actual Wall Remaining': {
                const fields = {
                    customerName: { isRequired: true },
                    calPercent: { isRequired: true },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Svc Date' },
                    toDate: { isRequired: false, label: 'Thru' },
                    jobNumber: { isRequired: false, readOnly: true },
                    container: { isRequired: false, readOnly: true }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            }
                break;
            case 'Asset Certificate':
            case 'Asset Certificate - Non-Standard': {
                const fields = {
                    customerName: { isRequired: true },
                    serial: { isRequired: false },
                    isSignatureRequired: { isRequired: false },
                    //isSignedCertificate: { isRequired: false },
                    includeFailedInspection: { isRequired: false },
                    generateAsZip: { isRequired: false },
                    generateofflinezip: { isRequired: false },
                    includegauges: { isRequired: false },
                    orCondition: true,
                    customerLocation: { isRequired: false, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Svc Date' },
                    toDate: { isRequired: false, label: 'Thru' },
                    jobNumber: { isRequired: false, readOnly: true },
                    serviceOrder: { isRequired: false },
                    shippingTicket: { isRequired: false, readOnly: true },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: true, children: ['Family Code', 'Style', 'Nominal Size', 'Status', 'Manufacturer', 'Length', 'Mfg Item No'] },

                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Container History Report': {
                const fields = {
                    customerName: { isRequired: true },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Customer Location' },
                    orCondition: true,
                    container: { isRequired: false },
                    orAnotherCondition: true,
                    functionalLocation: { isRequired: false },
                    orAnotherOneMoreCondition: true,
                    serial: { isRequired: false },
                    orAnotherTwoMoreCondition: true,
                    equipmentNumber: { isRequired: false }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Asset Wall Reading History': {
                const fields = {
                    customerName: { isRequired: true },
                    serial: { isRequired: false },
                    orCondition: true,
                    customerLocation: { isRequired: false, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Svc Date' },
                    toDate: { isRequired: false, label: 'Thru' },
                    jobNumber: { isRequired: false, readOnly: true },
                    shippingTicket: { isRequired: false, readOnly: true },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: true, children: ['Family Code', 'Style', 'Nominal Size', 'Status', 'Manufacturer', 'Length', 'Mfg Item No'] },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Pressure Test Historical Chart - ACI':
            case 'Pressure Test Chart - ACI': {
                const fields = {
                    customerName: { isRequired: true },
                    serial: { isRequired: false },
                    orCondition: true,
                    customerLocation: { isRequired: false, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Svc Date' },
                    toDate: { isRequired: false, label: 'Thru' },
                    jobNumber: { isRequired: false, readOnly: true },
                    shippingTicket: { isRequired: false, readOnly: true },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: true, children: ['Family Code', 'Style', 'Nominal Size', 'Status', 'Manufacturer', 'Length', 'Mfg Item No'] },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Full Export': {
                const fields = {
                    facility: { isRequired: false },
                    customerName: { isRequired: false },
                    serial: { isRequired: false },
                    orCondition: true,
                    useCustomerLocation: { isRequired: false },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Svc Date' },
                    toDate: { isRequired: false, label: 'Thru' },
                    jobNumber: { isRequired: false, readOnly: true },
                    shippingTicket: { isRequired: false, readOnly: true },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: true, children: ['Family Code', 'Style', 'Nominal Size', 'Status', 'Manufacturer', 'Length', 'Mfg Item No'] },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Asset Utilization Report': {
                const fields = {
                    facility: { isRequired: false },
                    customerName: { isRequired: true },
                    customerLocation: { isRequired: true, readOnly: true, label: 'Customer Location' },
                    fromDate: { isRequired: true, label: 'Date Range' },
                    toDate: { isRequired: true, label: 'Thru' },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;

            case 'Assets Due For Inspection': {
                const fields = {
                    customerName: { isRequired: true },
                    useCustomerLocation: { isRequired: false },
                    customerLocation: { isRequired: true, readOnly: true, label: 'Current Location' },
                    servicePerformed: { isRequired: true, readOnly: true },
                    fromDate: { isRequired: true, label: 'Date Range' },
                    toDate: { isRequired: true, label: 'Thru' },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: true, children: ['Family Code', 'Style', 'Status', 'Nominal Size', 'Length', 'Manufacturer', 'Mfg Item No'] }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Assets on Location': {
                const fields = {
                    customerName: { isRequired: true },
                    useCustomerLocation: { isRequired: false },
                    customerLocation: { isRequired: true, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Begin Date' },
                    toDate: { isRequired: false, label: 'End Date' },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: true, children: ['Family Code', 'Style', 'Status', 'Nominal Size', 'Length', 'Manufacturer', 'Mfg Item No'] }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;

            case 'Insp/Rej Summary': {
                const fields = {
                    customerName: { isRequired: true },
                    customerLocation: { isRequired: true, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: true, label: 'Svc Date' },
                    toDate: { isRequired: true, label: 'Thru' },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Inspected Assets Report': {
                const fields = {
                    facility: { isRequired: false },
                    customerName: { isRequired: false },
                    useCustomerLocation: { isRequired: false },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Inspection Date' },
                    toDate: { isRequired: false, label: 'Thru' },
                    jobNumber: { isRequired: false, readOnly: true },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: true, children: ['Family Code', 'Style', 'Status', 'Nominal Size', 'Length', 'Manufacturer', 'Mfg Item No'] }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Rejected Assets Report': {
                const fields = {
                    customerName: { isRequired: true },
                    useCustomerLocation: { isRequired: false },
                    customerLocation: { isRequired: true, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Begin Date' },
                    toDate: { isRequired: false, label: 'Thru' },
                    jobNumber: { isRequired: false, readOnly: true },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: true, children: ['Family Code', 'Style', 'Nominal Size', 'Length', 'Manufacturer', 'Mfg Item No'] }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;

            case 'UT Readings Summary': {
                const fields = {
                    customerName: { isRequired: true },
                    jobNumber: { isRequired: true, readOnly: true },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Begin Date' },
                    toDate: { isRequired: false, label: 'End Date' },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: true, children: ['Family Code', 'Style', 'Status', 'Nominal Size', 'Length', 'Manufacturer', 'Mfg Item No'] }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Torque Readings Summary': {
                const fields = {
                    customerName: { isRequired: true },
                    jobNumber: { isRequired: false, readOnly: true },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Begin Date' },
                    toDate: { isRequired: false, label: 'End Date' },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: true, children: ['Family Code', 'Style', 'Status', 'Nominal Size', 'Length', 'Manufacturer', 'Mfg Item No'] }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'On Time Delivery': {
                const fields = {
                    facility: { isRequired: true },
                    customerName: { isRequired: false },
                    fromDate: { isRequired: true, label: 'Begin Date' },
                    toDate: { isRequired: true, label: 'End Date' },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Reject Reasons By Family Code': {
                const fields = {
                    familyCode: { isRequired: true },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;

            case 'Technician Tests Performed': {
                const fields = {
                    facility: { isRequired: false },
                    customerName: { isRequired: false },
                    familyCode: { isRequired: false },
                    fromDate: { isRequired: true, label: 'Begin Date' },
                    toDate: { isRequired: true, label: 'End Date' },
                    servicePerformed: { isRequired: false, readOnly: true },
                    testName: { isRequired: false, readOnly: true },
                    technician: { isRequired: false, readOnly: true }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;

            case 'FMC Gauges Due Info': {
                const fields = {
                    customerLocation: { isRequired: true, readOnly: false, label: 'Customer Location' },
                    manufacturer: { isRequired: false },
                    useCustomerLocation: { isRequired: false },
                    dueDate: { isRequired: true, label: 'Due Date' },
                    multifamilycode: { isRequired: false },
                    multistyle: { isRequired: false },
                    multimfgitem: { isRequired: false },
                    multislevels: { isRequired: false },
                    includeServiceLevels: { isRequired: false }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'FMC Gauges Location Info': {
                const fields = {
                    customerLocation: { isRequired: true, readOnly: false, label: 'Customer Location' }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Current Active Jobs': {
                const fields = {
                    facility: { isRequired: true },
                    customerName: { isRequired: false }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Jobs With Incomplete SL': {
                const fields = {
                    facility: { isRequired: true },
                    customerName: { isRequired: false },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Current Location' },
                    fromDate: { isRequired: false, label: 'Date Range' },
                    toDate: { isRequired: false, label: 'Thru' },
                    container: { isRequired: false, readOnly: true },
                    fontFamily: { isRequired: false, status: false, children: ['Family Code', 'Nominal Size', 'Length', 'Manufacturer', 'Mfg Item No'] },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Last Cert Detail': {
                const fields = {
                    customerName: { isRequired: true },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Current Location' },
                    jobNumber: { isRequired: true, readOnly: true }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Scope of Work': {
                const fields = {
                    customerName: { isRequired: true },
                    customerLocation: { isRequired: true, readOnly: true, label: 'Current Location' },
                    jobNumber: { isRequired: true, readOnly: true },
                    fromDate: { isRequired: false, label: 'Added Date' },
                    toDate: { isRequired: false, label: 'Thru' },
                    familyCode: { isRequired: false },
                    needService: { isRequired: false },
                    groupNumber: { isRequired: false },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Asset Age': {
                const fields = {
                    customerName: { isRequired: true },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Customer Location' },
                    facility: { isRequired: true },
                    orCondition: true,
                    region: { isRequired: false },
                    nominalSize: { isRequired: false },
                    manufacturer: { isRequired: false },
                    fromDate: { isRequired: true, label: 'From Date' },
                    toDate: { isRequired: true, label: 'End Date' }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Inspections And Rejection Report':
            case 'Inspections And Rejection Table': {
                const fields = {
                    customerName: { isRequired: true },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Customer Location' },
                    facility: { isRequired: false },
                    orCondition: true,
                    region: { isRequired: false },
                    familyCode: { isRequired: false },
                    nominalSize: { isRequired: false },
                    manufacturer: { isRequired: false },
                    fromDate: { isRequired: true, label: 'From Date' },
                    toDate: { isRequired: true, label: 'End Date' }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Reject Reasons Report': {
                const fields = {
                    customerName: { isRequired: true },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Customer Location' },
                    facility: { isRequired: false },
                    orCondition: true,
                    region: { isRequired: false },
                    familyCode: { isRequired: false },
                    manufacturer: { isRequired: false },
                    fromDate: { isRequired: true, label: 'From Date' },
                    toDate: { isRequired: true, label: 'End Date' }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Open Receiving Tickets':
            case 'Open Shipping Tickets': {
                const fields = {
                    facility: { isRequired: true },
                    customerName: { isRequired: false },
                    customerLocation: { isRequired: false, readOnly: true, label: 'Customer Location' },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Received Assets': {
                const fields = {
                    facility: { isRequired: true },
                    customerName: { isRequired: false },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Receiving Ticket':
                {
                    const fields = {
                        customerName: { isRequired: true },
                        customerLocation: { isRequired: false, readOnly: true, label: 'Location' },
                        recievedTicket: { isRequired: true, readOnly: true, label: 'Ticket' }
                    }
                    props.onReportRecieve(fields, reportName, reportUrl, categoryName);
                } break;
            case 'Shipping Ticket':
                {
                    const fields = {
                        customerName: { isRequired: true },
                        customerLocation: { isRequired: false, readOnly: true, label: 'Location' },
                        shippingTicket: { isRequired: true, readOnly: true, label: 'Ticket' }
                    }
                    props.onReportRecieve(fields, reportName, reportUrl, categoryName);
                } break;
            case 'Equipment Drop-Off':
                {
                    const fields = {
                        customerName: { isRequired: false },
                        dactive: { isRequired: false },
                        dcompleted: { isRequired: false },
                        fromDate: { isRequired: false, label: 'Creation Date' },
                        toDate: { isRequired: false, label: 'Thru' },
                        dropOffNumber: { isRequired: false },
                        jobNumber: { isRequired: false, readOnly: true },
                        facility: { isRequired: false }
                    }
                    props.onReportRecieve(fields, reportName, reportUrl, categoryName);
                } break;
            case 'Full Export - Admin': {
                const fields = {
                    customerName: { isRequired: true },
                    multicustomerLocation: { isRequired: false, readOnly: false, label: 'Customer Locations' },
                    multiregion: { isRequired: false }
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'PMITP Report': {
                const fields = {
                    customerName: { isRequired: true }
                    , currentLocation: { isRequired: false, readOnly: true, label: 'Current Location' }
                    , customerLocation: { isRequired: false, readOnly: true, label: 'Customer Location' }
                    , serial: { isRequired: false, label: 'Serial Number' }
                    , multimfgitem: { isRequired: false, readOnly: true, label: 'Part Number' }
                    , fromDate: { isRequired: false, label: 'Next Inspection Date' }
                    , toDate: { isRequired: false, label: 'Thru' },
                }
                props.onReportRecieve(fields, reportName, reportUrl, categoryName);
            } break;
            case 'Vendor Certificate':
                props.onReportRecieve({}, reportName, reportUrl);
                break;
            default: {
                props.onReportRecieve({}, '');
                break;
            }
        }
    }
    const title = 'Reports';
    const { controller } = props;
    const { reportsGroupedByCategoryName, defaultReport } = controller.state;
    useEffect(() => {
        validatedClass();
    }, [controller.state.reportName, props.controller.state])
    return (
        <div>
            <h5>{title}</h5>
            <TreeView data={reportsGroupedByCategoryName} onClickItem={onNodeClick} initialOpenNodes={['AssetReports']} initialActiveKey='AssetReports/ActualWallRemaining' />
        </div>
    )
}

const ReportForm = (props) => {

    const getFacilityName = (controller, binder, errors, reportName, facility) => {
        if (reportName !== 'Asset Age' && reportName !== 'Inspections And Rejection Report' && reportName !== 'Inspections And Rejection Table' && reportName !== 'Reject Reasons Report' && reportName !== 'Inspected Assets Report' && reportName !== 'Full Export') {
            return <div className='col-md-6'><Form.AutoComplete label='Facility' id='facility' readOnly={false} bind={binder.bind('selectedFacility')} error={errors.selectedFacility} search={Searchers.facilitySearch()} /> </div>
        } else if (reportName === 'Asset Age' || reportName === 'Inspections And Rejection Report' || reportName === 'Inspections And Rejection Table' || reportName === 'Reject Reasons Report') {
            return <div className='col-md-6'><Form.MultiSelect label='Facility' id='multifacility' readOnly={false} bind={binder.bind('selectedFacility')} error={errors.selectedFacility} search={Searchers.facilitySearch()} /> </div>
        }
        else if (reportName === 'Inspected Assets Report' || reportName === 'Full Export') {
            return <div className='col-md-12'><div><div className='row'><div className='col-md-6'><div><Form.AutoComplete label='Facility' id='facility' readOnly={false} bind={binder.bind('selectedFacility')} error={errors.selectedFacility} search={Searchers.facilitySearch()} /></div></div></div></div></div>
        }
    }

    const customerSearch = () => {
        const results = [{ id: LoginService.loginInfo.customerId, name: LoginService.loginInfo.customerName }];
        return results;
    }

    const getCustomerName = (controller, binder, errors, reportName, customer) => {
        if (reportName === 'Asset Certificate' || reportName === 'Asset Certificate - Non-Standard') {
            return <div className='col-md-6'><div><div className='row'><div className='col-md-12'><div><Form.AutoComplete label='Customer' id='customerName' readOnly={false} bind={binder.bind('selectedCustomer')} error={errors.selectedCustomer} search={LoginService.isCustomer ? customerSearch : Searchers.customerSearch()} /> </div></div></div></div></div>
        }
        if (reportName === 'PMITP Report') {
            return <div className='col-md-12'><div><div className='row'><div className='col-md-6'><div><Form.AutoComplete label='Customer' id='customerName' readOnly={false} bind={binder.bind('selectedCustomer')} error={errors.selectedCustomer} search={LoginService.isCustomer ? customerSearch : Searchers.technipCustomerSearch()} /> </div></div></div></div></div>
        }
        else if (reportName === 'Full Export' || reportName === 'Inspected Assets Report') {
            const s = controller.state;
            return <div className='col-md-6'><div><div></div><Form.AutoComplete label='Customer' id='customerName' readOnly={false} bind={binder.bind('selectedCustomer')} error={errors.selectedCustomer} search={LoginService.isCustomer
                ? customerSearch
                : Searchers.customerSearchByFacility(s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : Utils.emptyGuid)} /> </div></div>
        }
        return <div className='col-md-6'><div><div></div><Form.AutoComplete label='Customer' id='customerName' readOnly={false} bind={binder.bind('selectedCustomer')} error={errors.selectedCustomer} search={LoginService.isCustomer
            ? customerSearch
            : Searchers.customerSearch()} /> </div></div>
    }

    const getMultiCustomerName = (controller, binder, errors, reportName, customer) => {
        return <div className='col-md-6'> <Form.MultiSelect label='Customers' id='multicustomerName' readOnly={false} bind={binder.bind('selectedMultiCustomer')} error={errors.selectedMultiCustomer} search={LoginService.isCustomer
            ? customerSearch
            : Searchers.customerSearch()} /> </div>
    }

    const getSerial = (controller, binder, errors, reportName, serial) => {
        return <div className='col-md-6'> <Form.Input label='Serial Number' id='serial' maxLength='50' bind={binder.bind('serialNo')} error={errors.serialNo} /></div>;
    }

    const getOrCondition = (controller, binder, errors, reportName, orCondition) => {
        return <div className='col-md-12'><div className='text-center my-2'><span className='h4'>Or</span></div></div>
    }

    const getIsSignatureRequired = (controller, binder, errors) => {
        const s = controller.state;
        return <div className='col-md-2' > <div id='isSignatureRequired'><Form.CheckBox label='Signature Required?' bind={binder.bind('isSignatureRequired')} error={errors.isSignatureRequired} /></div></div>;
    }

    const getIncludeFailedInspection = (controller, binder, errors) => {
        const s = controller.state;
        const isIpad = navigator.userAgent.match(/iPad/i) != null;
        // const divClass =isIpad?'col-md-3':'col-md-2';
        return <div className='col-md-3' > <div id='includeFailedInspection'><Form.CheckBox label='Include Failed Inspection?' bind={binder.bind('includeFailedInspection')} error={errors.includeFailedInspection} /></div></div>;
    }

    const getSignedCertificate = (controller, binder, errors) => {
        const s = controller.state;
        const canEnable = controller.CanEnableSignedCertificate();
        const isIpad = navigator.userAgent.match(/iPad/i) != null;
        // const divClass =isIpad?'col-md-3':'col-md-2';
        return <div className='col-md-2'> <div id='isSignedCertificate'><Form.CheckBox disabled={!canEnable} enabled={canEnable} label='Signed Certificate?' enabled='false' bind={binder.bind('isSignedCertificate')} error={errors.includeFailedInspection} /></div></div>;
    }

    const getIncludeServiceLevels = (controller, binder, errors) => {
        const s = controller.state;
        return <div className='col-md-6' > <div id='includeServiceLevels'><Form.CheckBox label='Include ServiceLevels that are not performed' bind={binder.bind('includeServiceLevels')} error={errors.includeServiceLevels} /></div></div>;
    }

    const getGenerateAsZip = (controller, binder, errors) => {
        const s = controller.state;
        return <div className='col-md-2' > <div id='generateAsZip'><Form.CheckBox label='Generate as Zip' bind={binder.bind('generateAsZip')} error={errors.generateAsZip} /></div></div>;
    }

    const getUseCustomerLocation = (controller, binder, errors, reportName, useCustomerLocation) => {
        const s = controller.state;
        const { orCondition } = s.formFields;
        if (reportName === 'FMC Gauges Due Info') {
            return <div className='col-md-6' > <div id='useCustomerLocation3'><Form.CheckBox label='Use current location instead of customer location' bind={binder.bind('useCustomerLocation')} error={errors.useCustomerLocation} /></div></div>;
        }
        if (orCondition) {
            return <div className='col-md-9 col-md-offset-3'> <div id='useCustomerLocation1'><Form.CheckBox label='Use customer location instead of current location' bind={binder.bind('useCustomerLocation')} error={errors.useCustomerLocation} /></div></div>;
        } else {
            return <div className='col-md-12' > <div id='useCustomerLocation2'><Form.CheckBox label='Use customer location instead of current location' bind={binder.bind('useCustomerLocation')} error={errors.useCustomerLocation} /></div></div>;
        }
    }

    const getCustomerLocation = (controller, binder, errors, reportName, customerLocation) => {
        const defaultGUID = Utils.emptyGuid;
        const smallSizeControl = ['Assets Due For Inspection', 'Insp/Rej Summary', 'Last Cert Detail', 'Scope of Work', 'Receiving Ticket', 'Shipping Ticket', 'Inspected Assets Report', 'Full Export', 'Container History Report', 'PMITP Report'];
        const s = controller.state;
        let customerLocationComp = '';
        if (reportName === 'FMC Gauges Due Info' || reportName === 'FMC Gauges Location Info' || reportName === 'Asset Age' || reportName === 'Inspections And Rejection Report' || reportName === 'Inspections And Rejection Table' || reportName === 'Reject Reasons Report') {
            customerLocationComp = <div className='col-md-6'><div><div><div><Form.MultiSelect label={customerLocation.label} id='multiCustomerLocation' readOnly={customerLocation.readOnly} bind={binder.bind('selectedCustomerLocation')} error={errors.selectedCustomerLocation} search={Searchers.getReportsLocationLookupValues({ facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, customerId: '09673009-3F0C-47A4-9557-C34ABB9A2EF9' })} /> </div></div></div></div>
        } else if (smallSizeControl.includes(reportName)) {
            customerLocationComp = <div className='col-md-6'><div><div><Form.AutoComplete label={customerLocation.label} id='customerLocation' readOnly={customerLocation.readOnly} bind={binder.bind('selectedCustomerLocation')} error={errors.selectedCustomerLocation} search={Searchers.getReportsLocationLookupValues({ facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, customerId: s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : defaultGUID })} /> </div></div></div>
        } else if (reportName !== 'FMC Gauges Due Info' && reportName !== 'FMC Gauges Location Info' && reportName !== 'Asset Age' && reportName !== 'Inspections And Rejection Report' && reportName !== 'Inspections And Rejection Table' && reportName !== 'Reject Reasons Report') {
            customerLocationComp = <div className='col-md-12'><div className='row'><div className='col-md-6'><div><div><Form.AutoComplete label={customerLocation.label} id='customerLocation' readOnly={customerLocation.readOnly} bind={binder.bind('selectedCustomerLocation')} error={errors.selectedCustomerLocation} search={Searchers.getReportsLocationLookupValues({ facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, customerId: s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : defaultGUID })} /> </div></div></div></div></div>
        } else {
            customerLocationComp = null;
        }
        return customerLocationComp;
    }

    const getFunctionalLocation = (controller, binder, errors, reportName, functionalLocation) => {
        const defaultGUID = Utils.emptyGuid;
        const s = controller.state;
        return <div className='col-md-6'> <Form.AutoComplete label='Functional Location' id='functionalLocation' readOnly={false} bind={binder.bind('selectedFuncLocation')} error={errors.selectedFuncLocation} search={Searchers.getFunctionalLocation({ customerId: s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : defaultGUID, containerId: s.selectedContainer && s.selectedContainer.id ? s.selectedContainer.id : defaultGUID, facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, isRental: true })} /> </div>;
    }

    const getEquipmentNumber = (controller, binder, errors, reportName, equipmentNumber) => {
        return <div className='col-md-6'> <Form.Input label='Equipment Number' id='equipment' maxLength='50' bind={binder.bind('equipmentNumber')} error={errors.serialNo} /></div>;
    }

    const getMultiCustomerLocation = (controller, binder, errors, reportName, customerLocation) => {
        const s = controller.state;
        if (reportName === 'Full Export - Admin') {
            return <div className='col-sm-6'> <Form.MultiSelect label={customerLocation.label} id='multicustomerLocation' readOnly={customerLocation.readOnly} bind={binder.bind('selectedMultiCustomerLocation')} error={errors.selectedMultiCustomerLocation} search={Searchers.getReportsLocationLookupValues({ facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, customerId: '09673009-3F0C-47A4-9557-C34ABB9A2EF9' })} /> </div>
        }
        else {
            return <div className='col-sm-6'> <Form.AutoComplete label={customerLocation.label} id='multicustomerLocation' readOnly={customerLocation.readOnly} bind={binder.bind('selectedMultiCustomerLocation')} error={errors.selectedMultiCustomerLocation} search={controller.getReportsLocationLookupValues({ facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, customerId: s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : Utils.defaultGUID })} multiple={true} style={{ height: 106 }} /> </div>
        }
    }

    const getServicePerformed = (controller, binder, errors, reportName, servicePerformed) => {
        const defaultGUID = Utils.emptyGuid;
        const s = controller.state;
        return <div className='col-md-6'> <Form.AutoComplete label='Service Performed' id='servicePerformed' readOnly={servicePerformed.readOnly} bind={binder.bind('selectedServicePerformed')} error={errors.selectedServicePerformed} search={Searchers.getServicelevelList(s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : defaultGUID)} /> </div>;
    }

    const getCurrentLocation = (controller, binder, errors, reportName, currentLocation) => {
        const defaultGUID = Utils.emptyGuid;
        const s = controller.state;
        return <div className='col-md-6'>
            <Form.AutoComplete label='Current Location' readOnly={currentLocation.readOnly} bind={binder.bind('selectedCurrentLocation')} error={errors.selectedCurrentLocation}
                search={Searchers.getReportsLocationLookupValues({ facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, customerId: s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : defaultGUID })} />
        </div>
    }

    const getTestName = (controller, binder, errors, reportName, testName) => {
        const defaultGUID = Utils.emptyGuid;
        const s = controller.state;
        return <div className='col-md-6'> <Form.AutoComplete label='Test Name' id='testName' readOnly={testName.readOnly} bind={binder.bind('selectedTestName')} error={errors.selectedTestName}
            search={Searchers.getServicelevelTestNamelist(s.selectedServicePerformed && s.selectedServicePerformed.id ? s.selectedServicePerformed.id : defaultGUID)} /> </div>;
    }

    const getTechnician = (controller, binder, errors, reportName, technician) => {
        const s = controller.state;
        return <div className='col-md-6'> <Form.AutoComplete label='Technician' id='technician' readOnly={technician.readOnly} bind={binder.bind('selectedTechnician')} error={errors.selectedTechnician}
            search={Searchers.getTechnicianList(s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId)} /> </div>;
    }

    const handleKeyUp = (evt) => {
        try {
            const value = parseInt(evt.target.value, 10);
            if (value < 0) {
                evt.target.value = 0;
            }

            if (value > 100) {
                evt.target.value = 100;
            }
        }
        catch (err) {
            evt.target.value = 0;
        }
    }

    const getCalPercent = (controller, binder, errors, reportName, calPercent) => {
        return <div className='col-md-6'><Form.Input type='number' id='calPercent' className='required' label='Cal Percent' min='1' max='100' maxLength='3' bind={binder.bind('calPercent')} onKeyUp={handleKeyUp} error={errors.calPercent} /></div>;
    }

    const getFromDate = (controller, binder, errors, reportName, fromDate) => {
        return <div className='col-md-6'><div><div><Form.Date label={fromDate.label} id='fromDate' placeholder='dd-mon-yyyy' bind={binder.bind('fromDate')} error={errors.fromDate} /></div></div></div>;
    }

    const getToDate = (controller, binder, errors, reportName, toDate) => {
        return <div className='col-md-6'><div><Form.Date label={toDate.label} id='toDate' placeholder='dd-mon-yyyy' bind={binder.bind('toDate')} error={errors.toDate} /></div></div>;
    }

    const getManufacturer = (controller, binder, errors, reportName, manufacturer) => {
        const defaultGUID = Utils.emptyGuid;
        if (reportName === 'FMC Gauges Due Info') {
            return <div className='col-md-6'> <div><div><Form.MultiSelect id='multiManufacturer' label='Manufacturer' readOnly={false} bind={binder.bind('selectedManufacturer')} error={errors.selectedManufacturer} search={Searchers.reportsListNameSearch('Manufacturer')} /> </div></div></div>;
        } else if (reportName !== 'FMC Gauges Due Info' && reportName !== 'Asset Age' && reportName !== 'Inspections And Rejection Report' && reportName !== 'Inspections And Rejection Table' && reportName !== 'Reject Reasons Report') {
            return <div className='col-md-6'><div><Form.AutoComplete id='manufacturer' label='Manufacturer' readOnly={false} bind={binder.bind('selectedManufacturer')} error={errors.selectedManufacturer} search={Searchers.reportsListNameSearch('Manufacturer')} /> </div></div>;
        } else if (reportName === 'Asset Age' || reportName === 'Inspections And Rejection Report' || reportName === 'Inspections And Rejection Table' || reportName === 'Reject Reasons Report') {
            return <div className='col-md-6'> <div><Form.MultiSelect id='multiManufacturer' label='Manufacturer' readOnly={false} bind={binder.bind('selectedManufacturer')} error={errors.selectedManufacturer} search={Searchers.reportsListNameSearch('Manufacturer')} /> </div></div>;
        } else {
            return <div className='col-md-6'> <div><Form.AutoComplete label='Manufacturer' readOnly={false} bind={binder.bind('selectedManufacturer')} error={errors.selectedManufacturer} search={Searchers.reportsListNameSearch('Manufacturer')} />        </div></div>;
        }
    }

    const getDueDate = (controller, binder, errors, reportName, dueDate) => {
        return <div className='col-md-6'> <Form.Date label={dueDate.label} id='dueDate' placeholder='dd-mon-yyyy' bind={binder.bind('dueDate')} error={errors.dueDate} /></div>;
    }

    const getNeedService = (controller, binder, errors, reportName, needService) => {
        return <div className='col-md-6'> <Form.CheckBox label='Need Service' className={needService.isRequired ? 'required' : ''} bind={binder.bind('needService')} error={errors.needService} /></div>;
    }

    const getRegion = (controller, binder, errors, reportName, region) => {
        return <div className='col-md-6'> <Form.AutoComplete label='Region' readOnly={false} bind={binder.bind('selectedRegion')} error={errors.selectedRegion} search={Searchers.getRegionList()} /> </div>;
    }

    const getMultiRegion = (controller, binder, errors, reportName, region) => {
        return <div className='col-md-6'><div> <Form.MultiSelect label='Regions' id='multiregion' readOnly={false} bind={binder.bind('selectedMultiRegion')} error={errors.selectedMultiRegion} search={Searchers.getRegionList()} /> </div></div>;
    }

    const getJobNumber = (controller, binder, errors, reportName, jobNumber) => {
        const defaultGUID = Utils.emptyGuid;
        const s = controller.state;
        return <div className='col-md-6'><div><Form.AutoComplete label='Job Number' id='jobNumber' readOnly={jobNumber.readOnly} bind={binder.bind('selectedJobNumber')} error={errors.selectedJobNumber} search={Searchers.reportsJobNumberSearch({ customerId: s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : defaultGUID, facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, locationId: s.selectedCustomerLocation && s.selectedCustomerLocation.id ? s.selectedCustomerLocation.id : defaultGUID })} /> </div></div>;
    }

    const getShippingTicket = (controller, binder, errors, reportName, shippingTicket) => {
        const defaultGUID = Utils.emptyGuid;
        const s = controller.state;
        if (reportName == 'Shipping Ticket') {
            return <div className='col-md-6'> <Form.AutoComplete label='Ticket' id='shippingTicket' readOnly={shippingTicket.readOnly} bind={binder.bind('selectedShippingTicket')} error={errors.selectedShippingTicket} search={Searchers.getShippingTicketList({ facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, customerId: s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : defaultGUID, locationId: s.selectedCustomerLocation && s.selectedCustomerLocation.id ? s.selectedCustomerLocation.id : defaultGUID })} /> </div>;
        }
        return <div className='col-md-6'> <Form.AutoComplete label='Shipping Ticket' id='shippingTicket' readOnly={shippingTicket.readOnly} bind={binder.bind('selectedShippingTicket')} error={errors.selectedShippingTicket} search={Searchers.getShippingTicketList({ facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, customerId: s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : defaultGUID, locationId: s.selectedCustomerLocation && s.selectedCustomerLocation.id ? s.selectedCustomerLocation.id : defaultGUID })} /> </div>;
    }

    const getRecievedTicket = (controller, binder, errors, reportName, recievedTicket) => {
        const defaultGUID = Utils.emptyGuid;
        const s = controller.state;
        return <div className='col-md-6'> <Form.AutoComplete label='Ticket' id='recievedTicket' readOnly={recievedTicket.readOnly} bind={binder.bind('selectedRecievedTicket')} error={errors.selectedRecievedTicket} search={Searchers.getRecievedTicketList({ facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, customerId: s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : defaultGUID, locationId: s.selectedCustomerLocation && s.selectedCustomerLocation.id ? s.selectedCustomerLocation.id : defaultGUID })} /> </div>;
    }

    const getContainer = (controller, binder, errors, reportName, container) => {
        const defaultGUID = Utils.emptyGuid;
        const s = controller.state;
        return <div className='col-md-6'> <Form.AutoComplete label='Container' id='container' readOnly={container.readOnly} bind={binder.bind('selectedContainer')} error={errors.selectedContainer} search={Searchers.getContainerList({ customerId: s.selectedCustomer && s.selectedCustomer.id ? s.selectedCustomer.id : defaultGUID, facilityId: s.selectedFacility && s.selectedFacility.id ? s.selectedFacility.id : LoginService.loginInfo.facilityId, isRental: true })} /> </div>;
    }

    const getFontFamily = (controller, binder, errors, reportName, fontFamily) => {
        let data = fontFamily.children.map((member, i) => {
            switch (member) {
                case 'Family Code': return getFamilyCode(controller, binder, errors, reportName, fontFamily);
                case 'Style': return getStyle(controller, binder, errors, reportName, fontFamily);
                case 'Nominal Size': return getNominalSize(controller, binder, errors, reportName, fontFamily);
                case 'Status': return getStatus(controller, binder, errors, reportName, fontFamily);
                case 'Manufacturer': return getManufacturer(controller, binder, errors, reportName, fontFamily);
                case 'Length': return getLength(controller, binder, errors, reportName, fontFamily);
                case 'Mfg Item No': return getMFGItemNo(controller, binder, errors, reportName, fontFamily);
            }
        });
        return data;
    }

    const getFamilyCode = (controller, binder, errors, reportName, familyCode) => {
        const s = controller.state;
        if (reportName == 'Technician Tests Performed') {
            return <div className='col-md-12'><div className='row'><div className='col-md-6'><div><div><Form.AutoComplete label='Family Code' id='familyCode' readOnly={false} bind={binder.bind('selectedFamilyCode')} error={errors.selectedFamilyCode} search={Searchers.reportsListNameSearch('Family Code')} /> </div></div></div></div></div>;
        } else if (reportName === 'FMC Gauges Due Info') {
            return <div className='col-md-6'> <Form.MultiSelect label='Family Code' id='multifamilyCode' readOnly={false} bind={binder.bind('selectedMultiFamilyCode')} error={errors.selectedMultiFamilyCode} search={Searchers.reportsListNameSearch('Family Code')} />  </div>;
        } else if (reportName !== 'Inspections And Rejection Report' && reportName !== 'Inspections And Rejection Table' && reportName !== 'Reject Reasons Report') {
            return <div className='col-md-6'> <Form.AutoComplete label='Family Code' id='familyCode' readOnly={false} bind={binder.bind('selectedFamilyCode')} error={errors.selectedFamilyCode} search={Searchers.reportsListNameSearch('Family Code')} /> </div>;
        } else if (reportName === 'Inspections And Rejection Report' || reportName === 'Inspections And Rejection Table' || reportName === 'Reject Reasons Report') {
            return <div className='col-md-6'> <Form.MultiSelect label='Family Code' id='multifamilyCode' readOnly={false} bind={binder.bind('selectedFamilyCode')} error={errors.selectedFamilyCode} search={Searchers.reportsListNameSearch('Family Code')} />  </div>;
        } else {
            return <div className='col-md-6'> <Form.AutoComplete label='Family Code' id='familyCode' readOnly={false} bind={binder.bind('selectedFamilyCode')} error={errors.selectedFamilyCode} search={Searchers.reportsListNameSearch('Family Code')} />       </div>;
        }
    }

    const getGroupNumber = (controller, binder, errors, reportName, groupNumber) => {
        return <div className='col-md-6'> <Form.Input label='Group Number' maxLength='50' id='groupNumber' bind={binder.bind('groupNumber')} error={errors.groupNumber} /></div>;
    }


    const getNominalSize = (controller, binder, errors, reportName, nominalSize) => {
        if (reportName === 'Asset Age' || reportName === 'Inspections And Rejection Report' || reportName === 'Inspections And Rejection Table' || reportName === 'Reject Reasons Report') {
            return <div className='col-md-6'> <Form.MultiSelect label='Nominal Size' id='multinominalSize' readOnly={false} bind={binder.bind('selectedNominalSize')} error={errors.selectedNominalSize} search={Searchers.reportsListNameSearch('Nominal Size')} />  </div>;
        } else {
            return <div className='col-md-6'> <Form.AutoComplete label='Nominal Size' readOnly={false} bind={binder.bind('selectedNominalSize')} error={errors.selectedNominalSize} search={Searchers.reportsListNameSearch('Nominal Size')} />       </div>
        }
    }

    const getStyle = (controller, binder, errors, reportName, fontFamily) => {
        let styleComponent = null;
        if (reportName === 'FMC Gauges Due Info') {
            styleComponent = <div className='col-md-6'> <div><div> <Form.MultiSelect label='Style' id='multiStyle' className={fontFamily.isRequired ? 'required' : ''} readOnly={false} bind={binder.bind('selectedMultiStyle')} error={errors.selectedMultiStyle} search={Searchers.reportsListNameSearch('Style')} /></div></div></div>;
        }
        else {
            styleComponent = <div className='col-md-6'> <Form.AutoComplete label='Style' id='style' className={fontFamily.isRequired ? 'required' : ''} readOnly={false} bind={binder.bind('selectedStyle')} error={errors.selectedStyle}
                search={Searchers.reportsListNameSearch('Style')} /></div>;
        }
        return styleComponent;
    }

    const getStatus = (controller, binder, errors, reportName, fontFamily) => {
        return <div className='col-md-6'> <Form.AutoComplete label='Status' className={fontFamily.isRequired ? 'required' : ''} readOnly={false} bind={binder.bind('selectedStatus')} error={errors.selectedStatus}
            search={Searchers.reportsListNameSearch('Inspection Status')} />
        </div>
    }


    const getLength = (controller, binder, errors, reportName, fontFamily) => {
        return <div className='col-md-6'> <Form.AutoComplete label='Length' className={fontFamily.isRequired ? 'required' : ''} readOnly={false} bind={binder.bind('selectedLength')} error={errors.selectedLength}
            search={Searchers.reportsListNameSearch('Length')} />
        </div>;
    }

    const getMFGItemNo = (controller, binder, errors, reportName, fontFamily) => {
        let mfgItemNoComponent = null;
        const s = controller.state;
        if (reportName === 'FMC Gauges Due Info') {
            mfgItemNoComponent = <div className='col-md-6'> <div><div> <Form.MultiSelect id='multiMfgItemNo' label='Mfg Item No' className={fontFamily.isRequired ? 'required' : null} readOnly={false} bind={binder.bind('selectedMultiMfgItem')} error={errors.selectedMultiMfgItem} search={Searchers.reportsListNameSearch('MFGItemNo')} /></div></div></div>;
        } else if (reportName === 'PMITP Report' || reportName === 'Asset Certificate' || reportName === 'Asset Certificate - Non-Standard') {
            const hasDescription = ['Asset Certificate', 'Asset Certificate - Non-Standard'].indexOf(reportName) > -1;
            mfgItemNoComponent = <div className='col-md-6'> <Form.AutoComplete label='Mfg Item No' id='mfgItemNo' className={fontFamily.isRequired ? 'required' : ''} readOnly={fontFamily.readOnly} bind={binder.bind('selectedMfgItemNo')} error={errors.selectedMfgItemNo}
                search={Searchers.reportsMFGItemNoSearch(s.selectedCustomer ? s.selectedCustomer.id : Utils.emptyGuid, hasDescription)} /></div>;
        }
        else {
            mfgItemNoComponent = <div className='col-md-6'> <Form.AutoComplete label='Mfg Item No' id='mfgItemNo' className={fontFamily.isRequired ? 'required' : ''} readOnly={false} bind={binder.bind('selectedMfgItemNo')} error={errors.selectedMfgItemNo}
                search={Searchers.reportsListNameSearch('MFGItemNo')} /></div>;
        }
        return mfgItemNoComponent;
    }

    const getSLevels = (controller, binder, errors, reportName, fontFamily) => {
        return <div className='col-md-6'> <Form.MultiSelect label='Service Level' id='multislevels' className={fontFamily.isRequired ? 'required' : null} readOnly={false} bind={binder.bind('selectedMultiSLevels')} error={errors.selectedMultiSLevels}
            search={Searchers.getReportsSLevelLookupValues()} />  </div>;
    }

    const getServiceOrder = (binder) => {
        return <div className='col-md-6'> <Form.Input type='number' label='Service Order' id='serviceOrder' bind={binder.bind('selectedServiceOrder')} /> </div>;
    }

    const dactive = (binder) => {
        return <div className='col-md-1'>
            <label className='field-label mr-1'><Form.RadioInput
                bind={binder.bind('searchActive')} />Active</label></div>
            ;
    }

    const dcompleted = (binder) => {
        return <div className='col-md-1'><label className='field-label mr-1'><Form.RadioInput
            bind={binder.bind('searchCompleted')} />Completed</label></div>;
    }

    const getDropOffNumber = (binder) => {
        return <div className='col-md-6'> <Form.Input type='number' label='Drop-Off Number' id='dropOff' bind={binder.bind('dropOffNumber')} /></div>;
    }

    const createForm = () => {
        const controller = props.controller;
        const binder = props.binder;
        const errors = controller.state.errors;
        const { formDisplay, formFields, reportName } = controller.state;
        const { customerName, serial, currentLocation, customerLocation, facility, servicePerformed, testName, technician, calPercent, fromDate, toDate, dueDate, jobNumber, shippingTicket, recievedTicket, container, familyCode, nominalSize, manufacturer, region, needService, useCustomerLocation, groupNumber, fontFamily, orCondition } = formFields;
        const s = controller.state;
        const defaultGUID = Utils.emptyGuid;
        const reportTypeDisable = reportName === 'Asset Certificate' || reportName === 'Asset Certificate - Non-Standard' ? true : false;
        const reportControls = Object.keys(formFields).map((control, i) => {
            switch (control) {
                case 'facility': return getFacilityName(controller, binder, errors, reportName, formFields[control]);
                case 'customerName': return getCustomerName(controller, binder, errors, reportName, formFields[control]);
                case 'serial': return getSerial(controller, binder, errors, reportName, formFields[control]);
                case 'isSignatureRequired': return getIsSignatureRequired(controller, binder, errors);
                case 'isSignedCertificate': return getSignedCertificate(controller, binder, errors);
                case 'includeFailedInspection': return getIncludeFailedInspection(controller, binder, errors);
                case 'generateAsZip': return getGenerateAsZip(controller, binder, errors);
                case 'orCondition': return getOrCondition(controller, binder, errors, reportName, formFields[control]);
                case 'orAnotherCondition': return getOrCondition(controller, binder, errors, reportName, formFields[control]);
                case 'orAnotherOneMoreCondition': return getOrCondition(controller, binder, errors, reportName, formFields[control]);
                case 'orAnotherTwoMoreCondition': return getOrCondition(controller, binder, errors, reportName, formFields[control]);
                case 'useCustomerLocation': return getUseCustomerLocation(controller, binder, errors, reportName, formFields[control]);
                case 'customerLocation': return getCustomerLocation(controller, binder, errors, reportName, formFields[control]);
                case 'functionalLocation': return getFunctionalLocation(controller, binder, errors, reportName, formFields[control]);
                case 'equipmentNumber': return getEquipmentNumber(controller, binder, errors, reportName, formFields[control]);
                case 'servicePerformed': return getServicePerformed(controller, binder, errors, reportName, formFields[control]);
                case 'testName': return getTestName(controller, binder, errors, reportName, formFields[control]);
                case 'technician': return getTechnician(controller, binder, errors, reportName, formFields[control]);
                case 'calPercent': return getCalPercent(controller, binder, errors, reportName, formFields[control]);
                case 'fromDate': return getFromDate(controller, binder, errors, reportName, formFields[control]);
                case 'toDate': return getToDate(controller, binder, errors, reportName, formFields[control]);
                case 'manufacturer': return getManufacturer(controller, binder, errors, reportName, formFields[control]);
                case 'dueDate': return getDueDate(controller, binder, errors, reportName, formFields[control]);
                case 'needService': return getNeedService(controller, binder, errors, reportName, formFields[control]);
                case 'groupNumber': return getGroupNumber(controller, binder, errors, reportName, formFields[control]);
                case 'region': return getRegion(controller, binder, errors, reportName, formFields[control]);
                case 'jobNumber': return getJobNumber(controller, binder, errors, reportName, formFields[control]);
                case 'serviceOrder': return getServiceOrder(binder);
                case 'shippingTicket': return getShippingTicket(controller, binder, errors, reportName, formFields[control]);
                case 'recievedTicket': return getRecievedTicket(controller, binder, errors, reportName, formFields[control]);
                case 'container': return getContainer(controller, binder, errors, reportName, formFields[control]);
                case 'familyCode': return getFamilyCode(controller, binder, errors, reportName, formFields[control]);
                case 'dactive': return dactive(binder);
                case 'dcompleted': return dcompleted(binder);
                case 'dropOffNumber': return getDropOffNumber(binder);
                case 'fontFamily': return getFontFamily(controller, binder, errors, reportName, formFields[control]);
                case 'multicustomerName': return getMultiCustomerName(controller, binder, errors, reportName, formFields[control]);
                case 'multicustomerLocation': return getMultiCustomerLocation(controller, binder, errors, reportName, formFields[control]);
                case 'multiregion': return getMultiRegion(controller, binder, errors, reportName, formFields[control]);
                case 'currentLocation': return getCurrentLocation(controller, binder, errors, reportName, formFields[control]);
                case 'multifamilycode': return getFamilyCode(controller, binder, errors, reportName, formFields[control]);
                case 'multistyle': return getStyle(controller, binder, errors, reportName, formFields[control]);
                case 'multimfgitem': return getMFGItemNo(controller, binder, errors, reportName, formFields[control]);
                case 'multislevels': return getSLevels(controller, binder, errors, reportName, formFields[control]);
                case 'includeServiceLevels': return getIncludeServiceLevels(controller, binder, errors);
                case 'generateofflinezip': return getGenerateAsOfflineZip(controller, binder, errors);
                case 'includegauges': return getIncludeGauges(controller, binder, errors);
            }
        });
        //const reportTypeDisable =  true;
        return (
            (<div>
                {formDisplay ?
                    <div id='report-form'>
                        <div className='row'>
                            <div className='col-md-3 offset-md-9'> <Form.AutoComplete label='Report Format' readOnly={reportTypeDisable} bind={binder.bind('reportFormat')} data={AppConstants.ReportFormat} minimumResultsForSearch={-1} allowClear={false} /> </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'> <Form.Header className='alert-info'>{reportName}</Form.Header> </div>
                            {/* <div className='col-md-3'> <Form.AutoComplete label='Report Format' readOnly={reportTypeDisable} bind={binder.bind('reportFormat')} data={AppConstants.ReportFormat} minimumResultsForSearch={-1} allowClear={false} /> </div> */}
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                {reportControls}
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <Button icon='fa-times' size='sm' variant='info' className='btn-action m-1' onClick={handleClear}>Clear</Button>{' '}
                            <Button icon='far fa-chart-bar' size='sm' variant='success' className='btn-action m-1' onClick={genarateReport} disabled={s.isGeneratingReport}>Generate</Button>
                        </div>
                    </div> : null}
            </div>
            )
        )
    }

    const handleClear = () => {
        props.controller.clear();
    }

    const genarateReport = () => {
        props.controller.getReport();
    }
    const getGenerateAsOfflineZip = (controller, binder, errors) => {
        const s = controller.state;
        const isIpad = navigator.userAgent.match(/iPad/i) != null;
        const divClass = isIpad ? 'col-md-1 div-zip-offline' : 'col-md-2';
        return (
            <>
                <div className={divClass} > <div id='generateofflineZip'><Form.CheckBox label='Zip Offline' bind={binder.bind('generateofflinezip')} error={errors.generateofflineZip} /></div></div>
            </>);
    }
    const getIncludeGauges = (controller, binder, errors) => {
        const s = controller.state;
        return <div className='col-md-3 div-include-gauges-offline'> <div id='includegauges'><Form.CheckBox label='Include Gauges?' bind={binder.bind('includegauges')} error={errors.includegauges} /></div></div>;
    }
    return (
        <div>
            {createForm()}
        </div>
    )
}

class VendorReport extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        this.props.controller.clear();
    }
    componentDidMount = () => {
        this.initializeMixin();
        // this.demandAdministrator();
    }

    static defaultProps = {
        controller: new VendorReportsController()
    }

    handleSend = () => {
        this.props.controller.sendMessage();
    }

    handleClear = () => {
        this.props.controller.clear();
    }

    generateReport = () => {
        this.props.controller.getReport();
    }

    customerSearch = () => {
        const results = [{ id: LoginService.loginInfo.customerId, name: LoginService.loginInfo.customerName }];
        return results;
    }

    render = () => {
        var binder = new Binder(this);
        var controller = this.props.controller;
        var errors = controller.state.errors;
        var certs = [];
        if (controller.state.certs) {
            certs = controller.state.certs;
        }

        const reportName = 'Vendor Uploaded Certificates';
        const defaultGUID = Utils.emptyGuid;

        return (
            <div>
                <div className='row'>
                    <div className='col-md-6'> <h4>{reportName}</h4> </div> <div className='col-sm-3'></div>
                </div><br />
                <div name="vendorDiv">
                    <form>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.AutoComplete label='Customer Name' readOnly={false} bind={binder.bind('selectedCustomerName')}
                                    error={errors.selectedCustomerName} search={LoginService.isCustomer
                                        ? this.customerSearch
                                        : Searchers.customerSearch()} className='required' />
                            </div>
                            <div className='col-md-6'>
                                <Form.AutoComplete label='Current Location' readOnly={!controller.state.selectedCustomerName}
                                    bind={binder.bind('selectedLocation')} error={errors.selectedLocation} search={Searchers.customerLocationSearch(controller.state.selectedCustomerName ? controller.state.selectedCustomerName.id : defaultGUID)} className='required' />
                            </div>
                            <div className='col-md-6'>
                                <Form.Input label='Serial' bind={binder.bind('serialNo')} error={errors.serialNo} className='required' />
                            </div>
                            <div className='col-md-6'>
                                <Form.Date label='Start Date' className='required' bind={binder.bind('startDate')} error={errors.startDate} />
                            </div>
                            <div className='col-md-6'>
                                <Form.Date label='End Date' className='required' bind={binder.bind('endDate')} error={errors.endDate} />
                            </div>
                        </div>
                        <div className='float-right'>
                            <Button icon='fa-times' variant='info' size='sm' className='btn-action m-1 action-button' onClick={this.handleClear}>Clear</Button>
                            <Button icon='far fa-chart-bar' variant='success' size='sm' className='btn-action m-1 action-button' onClick={this.generateReport}>Generate</Button>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                {certs && certs.length ? <CustomerCertificateTable data={certs} controller={controller} showSerialPrimar='true'></CustomerCertificateTable> : null}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const ReportsPageHeader = (props) => <PageHeader title={props.pageTitle} icon='far fa-chart-bar' />

class ReportsPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        this.props.controller.getReportList();
    }
    componentDidMount = () => {
        this.initializeMixin();
        this.demandReports();
    }

    static defaultProps = {
        controller: new ReportsController()
    }

    handleReportRecieved = (formDetails, reportName, reportUrl, categoryName) => {
        this.props.controller.getReportDetails(formDetails, reportName, reportUrl, categoryName);
        this.props.controller.loadValidator();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.controller.state.reportName !== this.props.controller.state.reportName) {
            { this.validatedClass() }
        }
    }

    render = () => {
        const controller = this.props.controller;
        const binder = new Binder(this);

        return (
            <Page {...this.props} pageTitle='InteServ · Reports' id='report-page'>
                <ReportsPageHeader pageTitle='Reports' />
                <Page.Content>
                    <div className='row'>
                        <div className='col-md-4 col-lg-3'>
                            <ReportTreeView onReportRecieve={this.handleReportRecieved} controller={controller} />
                        </div>
                        <div className='col-md-8 col-lg-9'>
                            {controller.state.reportName === 'Vendor Certificate' ?
                                <VendorReport /> : <ReportForm controller={controller} binder={binder} />}
                        </div>
                    </div>
                </Page.Content>
            </Page>
        );
    }
}

export default ReportsPage;

